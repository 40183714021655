<template>
  <CCard class="mb-0 pointer navigation_card" @click="showPage();">
    <CCardBody>
      <div class="d-flex">
        <div v-if="cardIcon" class="mr-2">
          <i v-bind:class="cardIcon"/>
        </div>
        <div class="flex-grow-1">
          <h2 class="m-0">{{cardTitle}}</h2>
          <p v-if="cardDescription" class="mt-1 mb-0">{{cardDescription}}</p>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'navigationCard',
  props: ['path', 'cardTitle', 'cardDescription', 'cardIcon'],
  methods: {
    showPage() {
      if(this.path) this.$router.push({path: this.path});
    }
  },
}
</script>
